import Link from "next/link";
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import {
  footerPrimaryPages,
  footerTemplate,
  footerIndusties1,
  footerIndusties2,
  footerIndusties3,
  footerIndusties4,
  footerResources,
  footerSolutions,
} from "../../utils/data";
import Logo from "../../assets/img/home/logo_white.svg";
import Image from "next/image";
const Footer = ({ footerLight, style, footerGradient }) => {
  return (
    <>
      <footer className="footer-section z-100">
        <main
          className={`footer-top pt-100 pb-5 ${
            footerLight ? "footer-light" : "bg-dark"
          } ${footerGradient ? "bg-gradient" : ""}  text-white`}
          style={style}
        >
          <section className="container">
            <article className="row justify-content-between px-5">
              <article className="col-md-12 col-lg-5 mb-md-4 mb-lg-0 ">
                <article className="footer-single-col">
                  <article className="footer-single-col mb-4">
                    <h3> Industries</h3>
                  </article>
                </article>
                <article className="row ">
                  <article className="col-md-6   mt-4 mt-md-0 mt-lg-0 pb-5">
                    <article className="footer-single-col ">
                      <div className="pb-3">
                        <h4 className="font-bold  font-size">
                          <span className=" border-2 border-white border-bottom pb-2">
                            Ride Hailing
                          </span>
                        </h4>
                      </div>
                      <ul className="list-unstyled footer-nav-list mb-lg-0">
                        {footerIndusties1.map((primaryPage, i) => (
                          <li key={i + 1}>
                            <Link href={primaryPage.href}>
                              <a className="text-decoration-none font-size_two">
                                {primaryPage.title}
                              </a>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </article>
                  </article>
                  <article className="col-md-6 mt-4 mt-md-0 mt-lg-0">
                    <article className="footer-single-col ">
                      <div className="pb-3">
                        <h4 className="font-bold  font-size" id="text">
                          <span className=" border-2 border-white border-bottom pb-2">
                            Commerce
                          </span>
                        </h4>
                      </div>
                      <ul className="list-unstyled footer-nav-list mb-lg-0">
                        {footerIndusties2.map((page, i) => (
                          <li key={i + 1}>
                            <Link href={page.href}>
                              <a className="text-decoration-none font-size_two">
                                {page.title}
                              </a>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </article>
                  </article>
                  <article className="col-md-6  mt-4 mt-md-0 mt-lg-0">
                    <article className="footer-single-col">
                      <div className="pb-3 pe-5">
                        <h4 className="font-bold  font-size">
                          <span className=" border-2 border-white border-bottom pb-2">
                            Logistics
                          </span>
                        </h4>
                      </div>
                      <ul className="list-unstyled footer-nav-list mb-lg-0">
                        {footerIndusties3.map((template, i) => (
                          <li key={i + 1}>
                            <Link href={template.href}>
                              <a className="text-decoration-none font-size_two">
                                {template.title}
                              </a>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </article>
                  </article>
                  <article className="col-md-6  mt-4 mt-md-0 mt-lg-0">
                    <article className="footer-single-col">
                      <div className="pb-3 pe-5">
                        <h4 className="font-bold  font-size">
                          <span className=" border-2 border-white border-bottom pb-2">
                            Services
                          </span>
                        </h4>
                      </div>
                      <ul className="list-unstyled footer-nav-list mb-lg-0">
                        {footerIndusties4.map((template, i) => (
                          <li key={i + 1}>
                            <Link href={template.href}>
                              <a className="text-decoration-none font-size_two">
                                {template.title}
                              </a>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </article>
                  </article>
                </article>
              </article>
              <article className="col-md-12 col-lg-7 mt-4 mt-md-0 mt-lg-0">
                <article className="row p">
                  <article className="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0 ">
                    <div className="footer-single-col ">
                      <h5 className=" font-size">Platform</h5>
                      <ul className="list-unstyled footer-nav-list mb-lg-5 pt-4">
                        {footerPrimaryPages.map((primaryPage, i) => (
                          <li key={i + 1}>
                            <Link href={primaryPage.href}>
                              <a className="text-decoration-none font-size_two mb-2">
                                {primaryPage.title}
                              </a>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </article>
                  <article className="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                    <div className="footer-single-col">
                      <h6 className=" font-size">Solutions</h6>
                      <ul className="list-unstyled footer-nav-list mb-lg-0 pt-4">
                        {footerSolutions.map((page, i) => (
                          <li key={i + 1}>
                            <Link href={page.href}>
                              <a className="text-decoration-none font-size_two mb-2">
                                {page.title}
                              </a>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </article>
                  <article className="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                    <div className="footer-single-col">
                      <h6 className=" font-size">Company</h6>
                      <ul className="list-unstyled footer-nav-list mb-lg-0 pt-4">
                        {footerTemplate.map((template, i) => (
                          <li key={i + 1}>
                            <Link href={template.href}>
                              <a className="text-decoration-none font-size_two mb-2">
                                {template.title}
                              </a>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="footer-single-col pt-4">
                      <h6 className=" font-size">Resources</h6>
                      <ul className="list-unstyled footer-nav-list mb-lg-0 pt-4">
                        {footerResources.map((page, i) => (
                          <li key={i + 1}>
                            <Link href={page.href}>
                              <a className="text-decoration-none font-size_two mb-2">
                                {page.title}
                              </a>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </article>
                </article>
              </article>
            </article>
          </section>
        </main>
        <main
          className={`footer-bottom ${
            footerLight ? "footer-light" : "bg-dark"
          } ${footerGradient ? "bg-gradient" : ""} text-white py-4`}
        >
          <section className="container">
            <article className="row justify-content-md-between  align-items-center px-5">
              <article className="col-md-7 col-lg-4">
                <article className="copyright-text d-flex justify-content-center align-items-center">
                  <Link href="/">
                    <a>
                      <Image
                        src={Logo}
                        alt="ZervX logo "
                        title="ZervX mobility software solutions"
                        className="img-fluid "
                        width={140}
                        height={65}
                        loading="lazy"
                      />
                    </a>
                  </Link>
                  <span className="mb-lg-0 mb-md-0 ps-4 font-size_two_bottom ">
                    <span className="d-flex">
                      Copyright &copy; 2025 BerarkRays
                    </span>
                  </span>
                </article>
              </article>
              <article className="col-lg-5 col-md-7 py-md-0 py-4 ">
                <article className="copyright-text d-flex justify-content-center">
                  <Link href="/gdpr-policy">
                    <a>
                      <span
                        className="mb-lg-0 mb-md-0 px-1 font-size_two_bottom d-flex align-items-center"
                        role="button"
                      >
                        GDPR Policy
                      </span>
                    </a>
                  </Link>
                  <Link href="/privacy-policy">
                    <a>
                      <span
                        className="mb-lg-0 mb-md-0 px-1 font-size_two_bottom d-flex align-items-center"
                        role="button"
                      >
                        Data Privacy
                      </span>
                    </a>
                  </Link>
                  <Link href="/terms-and-conditions">
                    <a>
                      <span
                        className="mb-lg-0 mb-md-0 px-1 font-size_two_bottom d-flex align-items-center"
                        role="button"
                      >
                        Terms & Conditions
                      </span>
                    </a>
                  </Link>
                </article>
              </article>
              <article className="col-md-4 col-lg-3 py-4">
                <article className="footer-single-col text-center text-lg-end text-md-end ">
                  <ul className="list-unstyled list-inline footer-social-list mb-0">
                    <li className="list-inline-item">
                      <Link href="https://www.facebook.com/people/ZervX-All-in-One-App/100076540476450/">
                        <a
                          target="_blank"
                          rel="noopener"
                          aria-label="Facebook logo"
                        >
                          <FaFacebook />
                        </a>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link href="https://www.linkedin.com/company/zervx-all-services-in-one-app/">
                        <a
                          target="_blank"
                          rel="noopener"
                          aria-label="LinkedIn logo"
                        >
                          <FaLinkedin />
                        </a>
                      </Link>
                    </li>
                  </ul>
                </article>
              </article>
            </article>
          </section>
        </main>
      </footer>
    </>
  );
};
export default Footer;
